<template>
  <div>
    <b-card no-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="readItems"
        :fields="fields"
      >
        <template #cell(type)="data">
          {{ fixType(data.item.type) }}
        </template>
        <template #cell(weekDoseLimitPerSource)="data">
          {{ convertToMicroSievert(data.item.weekDoseLimitPerSource) }}
        </template>
        <template #cell(yearDoseLimitPerSource)="data">
          {{ convertToMiliSievert(data.item.yearDoseLimitPerSource) }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  data() { return { items: [] } },
  computed: {
    readItems() { return this.items },
    fixType() {
      return type => this.$t(`areaTypeLimits.${type}`)
    },
    convertToMicroSievert() {
      return value => `${(10 ** 6) * value} μSv/${this.$t('areaTypeLimits.week')}`
    },
    convertToMiliSievert() {
      return value => `${(10 ** 3) * value} mSv/${this.$t('areaTypeLimits.year')}`
    },
    fields() {
      return [
        { key: 'type', label: this.$t('areaTypeLimits.table.type'), sortable: true },
        { key: 'weekDoseLimitPerSource', label: this.$t('areaTypeLimits.table.weekDoseLimitPerSource'), sortable: true },
        { key: 'yearDoseLimitPerSource', label: this.$t('areaTypeLimits.table.yearDoseLimitPerSource'), sortable: true },
      ]
    },
  },
  async created() { await this.load() },
  methods: {
    async load() {
      this.items = await this.$store.dispatch('areaTypeLimit/index')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@media (max-width: 767px){
  .small-devices-filterby{
    display: block;
  }
}
.dropdown button {
    padding: 0px;
}
.em-dropdown .dropdown-menu {
  max-height: 100px;
  overflow-y: hidden;
}
.dropdown-item{
  padding: 0 4px;
}
</style>
